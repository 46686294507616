import { Suspense, lazy } from "react"; 
import { UserContextProvider } from "./components/context/UserContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./components/PrivateRoute"; // Importa PrivateRoutes

// Components:
const SignIn = lazy(() => import("./components/users/login"));
const SignUp = lazy(() => import("./components/users/logout"));
const Profile = lazy(() => import("./components/users/profile"));
const Header = lazy(() => import("./components/header"));
const Principal = lazy(() => import("./paginas/Principal"));
const Peliculas = lazy(() => import("./paginas/Peliculas"));
const Series = lazy(() => import("./paginas/Series"));
const Casos = lazy(() => import("./paginas/Casos"));
const Categorias = lazy(() => import("./components/admin/Categorias"));
const Especialidades = lazy(() => import("./components/admin/Especialidades"));
const SubEspecialidades = lazy(() => import("./components/admin/SubEspecialidades"));
const Autores = lazy(() => import("./components/admin/Autores"));
const PalabrasClaves = lazy(() => import("./components/admin/PalabrasClaves"));
const Prueba11 = lazy(() => import("./components/Prueba11/Prueba11"));
const SeriesAdmin = lazy(() => import("./components/admin/SeriesAdmin"));
const TemporadasAdmin = lazy(() => import("./components/admin/TemporadasAdmin"));
const Ajustes = lazy(() => import("./paginas/Ajustes"));
const VideoDetail = lazy(() => import("./components/videos/videoDetail"));
const SerieDetail = lazy(() => import("./components/series/serieDetail"));
const SerieVDetail = lazy(() => import("./components/series/serieVDetail"));
const VideoForm = lazy(() => import("./components/videos/videoForm"));
const NotFound = lazy(()=> import("./paginas/NotFound"))

function App() {
  return (
    <UserContextProvider>
      <BrowserRouter>
        <Suspense fallback={
          <div className="mt-5" style={{ fontSize: "25px", display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column", alignItems: "center" }}>
            <div className="spinner-grow text-light mt-5" role="status">
              <span className="sr-only"></span>
            </div>
            Espera por favor
          </div>
        }>
          <StyledEngineProvider injectFirst>
            <Header />
            <Routes>
              {/* Rutas Públicas */}
              
              <Route path="/loginuser" element={<SignIn />} />
              <Route path="/logoutuser" element={<SignUp />} />
              
              {/* 🔒 Rutas Protegidas */}
              <Route element={<PrivateRoutes />}>
                <Route exact path="/" element={<Principal />} />
                <Route exact path="/Videos" element={<Peliculas />} />
                <Route exact path="/Series" element={<Series />} />
                <Route exact path="/Casos" element={<Casos />} />
                <Route path="/Perfil" element={<Profile />} />
                <Route path="/Prueba11" element={<Prueba11 />} />
                <Route path="/Ajustes" element={<Ajustes />} />
                <Route path="/Ajustes/Categorias" element={<Categorias />} />
                <Route path="/Ajustes/Especialidades" element={<Especialidades />} />
                <Route path="/Ajustes/SubEspecialidades" element={<SubEspecialidades />} />
                <Route path="/Ajustes/Autores" element={<Autores />} />
                <Route path="/Ajustes/PalabrasClaves" element={<PalabrasClaves />} />
                <Route path="/Ajustes/Series" element={<SeriesAdmin />} />
                <Route path="/Ajustes/Temporadas" element={<TemporadasAdmin />} />
                <Route path="/VideoForm" element={<VideoForm />} />
                <Route path="/updateVideo/:id" element={<VideoForm />} />
                <Route path="/seeVideo/:id" element={<VideoDetail />} />
                <Route path="/seeTemporada/:id" element={<SerieDetail />} />
                <Route path="/seeSerie/:id" element={<SerieVDetail />} />
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </StyledEngineProvider>
        </Suspense>
      </BrowserRouter>
    </UserContextProvider>
  );
}

export default App;
